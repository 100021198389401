import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
  Container,
  Typography,
  Alert,
  AlertTitle,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  Stack,
  Snackbar,
  CircularProgress,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import agent from "../../api/agent";
import Transitions from "./Transitions";
import ConfirmDialog from "./common/Dialog/ConfirmDialog";
import DispositionDialog from "./common/Dialog/DispositionDialog";
import useAuth from "hooks/useAuth";
import renderDynamicComponent from "./common/renderDynamicComponent";

export default function Workflow({
  record,
  componentMapping,
  workflowHandler,
  workflowData,
}: any) {
  const useStyles = makeStyles({
    tableContainer: {
      width: "100%",
      paddingTop: "70px",
      paddingBottom: "38px",
      paddingLeft: "40px",
      paddingRight: "40px",
      borderRadius: "16px",
      marginTop: "40px",
      minHeight: "25vh",
      // height: (props: any) => `${props.height ? props.height : "85vh"}`,
      boxShadow: (props: any) =>
        `0px 16px 32px -4px ${
          props?.boxShadowColor
            ? props?.boxShadowColor
            : "rgba(145,158,171,0.24)"
        }, 0px 0px 2px rgba(145,158,171,0.24)`,
      "& .MuiDataGrid-columnHeaderWrapper": {
        backgroundColor: "#f4f6f8",
        borderRadius: "8px",
      },
      "& .MuiTablePagination-select": {
        height: "30px",
      },
      "& .MuiDataGrid-toolbarContainer": {
        margin: "20px 10px 20px 20px",
      },
      "& .MuiDataGrid-footerContainer": {
        minHeight: "160px",
      },
      "& .MuiDataGrid-root": {
        border: "none",
      },
    },
    searchContainer: {
      flex: "1 auto",
    },
    textField: {
      "& .MuiOutlinedInput-root": {
        height: "56px",
      },
    },
    dataGrid: {
      width: "100%",
      padding: 8,
    },
    headerOpts: {
      marginLeft: "25px",
      marginTop: "25px",
    },
    customLabelStyle: {
      fontSize: "13px !important",
      fontWeight: "700 !important",
      color: "#212B36 !important",
    },
  });

  const classes = useStyles({});
  const { user } = useAuth();

  const ErrorContainer = ({
    showErrorContainer: show,
    errorContainerTitle: title,
    errorContainerList: errors = [],
  }: any) => {
    if (show) {
      const filterErrors = errors.filter((i: any) => i.reasonNotValid !== null);

      return (
        <>
          <Alert severity="error" sx={{ marginTop: 5 }}>
            <AlertTitle>{title}</AlertTitle>
            <ul>
              {filterErrors.map((err: any) => (
                <li key={err.id}>{err.reasonNotValid}</li>
              ))}
            </ul>
          </Alert>
        </>
      );
    }

    return null;
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#EAEAF0", //#784af4
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#EAEAF0",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme?.palette?.mode === "dark" ? theme?.palette?.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        <div className="QontoStepIcon-circle" />
      </QontoStepIconRoot>
    );
  };

  const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }: any) => ({
      color: "#006cff",
      display: "flex",
      height: 22,
      alignItems: "center",
      ...(ownerState.active && {
        color: "#8C8C8C",
      }),
      "& .QontoStepIcon-completedIcon": {
        color: "#006cff",
        zIndex: 1,
        fontSize: 18,
      },
      "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
      },
    })
  );

  const [activeStep, setActiveStep] = useState<any>();
  const [workflow, setWorkFlow] = useState<any>();
  const [stages, setStages] = useState<any>([]);
  const [transitionList, setTransitionList] = useState<any>([]);
  const [checklistWorkflow, setChecklistWorkflow] = useState<any>([]);
  const [showErrorContainer, setShowErrorContainer] = useState<any>(false);
  const [errorContainerTitle, setErrorContainerTitle] = useState<any>();
  const [errorContainerList, setErrorContainerList] = useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = useState<any>(false);
  const [patient, setPatient] = useState<any>();
  const [currentTransition, setCurrentTransition] = useState<any>();
  const [dispositions, setDispositions] = useState<any>({});
  const [errorMessageSnackbar, setErrorMessageSnackbar] = useState<any>();
  const [checklist, setChecklist] = useState<any>([]);

  const refreshWorkFlow = () => {
    setShowErrorContainer(false);
    setChecklist([]);
    fetchWorkFlow();
    fetchPatient();
  };

  useEffect(() => {
    fetchWorkFlow();
    fetchPatient();
  }, [record]);

  useEffect(() => {
    fetchTransitions();
  }, [stages]);

  useEffect(() => {
    const getFirstStage = stages?.sort((a: any, b: any) => a?.id - b?.id)[0];
    setActiveStep(patient?.stage?.id || getFirstStage?.order);
  }, [patient]);

  useEffect(() => {
    fetchChecklist(activeStep);
  }, [transitionList]);

  const fetchChecklist2 = async (activeStep: any) => {
    if (activeStep) {
      const getChecklist = await agent.Checklist.getChecklistById({
        checklistId: stages[activeStep - 1]?.id,
        id: record?.id,
      });
      setChecklist(getChecklist);
    }
  };

  const fetchChecklist = async (activeStep: any) => {
    if (activeStep) {
      const getChecklist = await agent.Checklist.getChecklistById({
        checklistId: stages[activeStep - 1]?.id,
        id: record?.id,
      });

      const sortChecklistItems = getChecklist.sort((a: any, b: any) => {
        const parseConditionA = JSON.parse(a.conditions);
        const parseConditionB = JSON.parse(b.conditions);
        
        return parseConditionA.order - parseConditionB.order;
      });
      setChecklist(getChecklist);
    }
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const steps = stages.map((i: any) => i?.name);

  const fetchWorkFlow = async () => {
    if (record?.id) {
      if (record?.workFlow?.code) {
        const getWorkflow = await agent.Workflow.getWorkflowByCode(
          record?.workFlow?.code
        );
        setWorkFlow(getWorkflow);
        setStages(getWorkflow?.stages);
      } else {
        setErrorMessageSnackbar("Workflow not found");
        setOpenSnackbar(true);
      }
    }
  };

  const fetchPatient = async () => {
    if (record?.id) {
      const getPatient = await agent.PatientEngagement.getPatientById(
        record?.id
      );
      setPatient(getPatient);
    }
  };

  const fetchTransitions = async () => {
    const getTransitions = await agent.Workflow.getTransitions(workflow?.id);
    setTransitionList(getTransitions);
  };

  const getActionProperties = (action: any) => {
    const { type } = action;

    return {
      type,
      handler: (data: any) => workflowHandler[`set${type}`]["handler"](data),
      component: type,
    };
  };

  const attemptTransition = async (id: any) => {
    const getTransitionId = transitionList.filter((i: any) => i.id === id);

    const { isValid, reasonNotValid } = await agent.Transition.attempt({
      transition: { id: getTransitionId?.[0]?.id },
      patient: { id: record?.id },
      apply: true,
    });

    const parseResultValidation =
      typeof isValid === "string" ? JSON.parse(isValid) : isValid;

    if (parseResultValidation) {
      await agent.Transition.updateStage({
        // Todo: esto debe ser dinamico en base a la transition
        patient: { id: record?.id }, // Todo: esto debe ser dinamico
        stage: { id: getTransitionId?.[0]?.toStagesId },
      });
      return true;
    } else {
      setShowErrorContainer(true);
      setErrorContainerTitle("Could not move to the next stage");
      setErrorContainerList([{ reasonNotValid }]);
      return false;
    }
  };

  const handleTransition = async (transition: any) => {
    const { ui } = JSON.parse(transition?.metadata);

    if (ui?.action && Object.keys(ui?.action).length > 0) {
      const actionProperties: any = getActionProperties(ui?.action);
      const handler = actionProperties.handler;

      handler(true);
      setCurrentTransition(transition);
      return;
    }

    const isValid = await attemptTransition(transition?.id);

    if (isValid) {
      refreshWorkFlow();
      setActiveStep(transition?.toStagesId);
    }
  };

  const handlerConfirmation = async () => {
    const isValid = await attemptTransition(currentTransition?.id);

    if (isValid) {
      refreshWorkFlow();
      setActiveStep(currentTransition?.toStagesId);
    }

    workflowHandler["setConfirmation"]["handler"](false);
  };

  const handlerDisposition = async () => {
    console.log("DEBUG PE handlerDisposition");

    const COMPLETED = 'Completed';

    const payload = {
      patient: { id: record?.id },
      transition: { id: currentTransition?.toStagesId },
      disposition: {
        ...dispositions,
        ...(workflowData?.noReasonForDisposition && { reason: COMPLETED }),
      },
    };
    console.log("DEBUG PE payload: ", payload);

    const isValid = await agent.PatientEngagement.customUpdate(payload);

    if (isValid) {
      refreshWorkFlow();
      setActiveStep(currentTransition?.toStagesId);
    }

    workflowHandler["setDisposition"]["handler"](false);
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessageSnackbar}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid item md={12}>
          <div className={classes.tableContainer}>
            <Box sx={{ width: "100%" }}>
              <h2 style={{ marginBottom: 50 }}>Workflow</h2>
              <Stepper
                alternativeLabel
                activeStep={activeStep - 1}
                connector={<QontoConnector />}
              >
                {steps.map((label: any) => {
                  return (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        classes={{
                          labelContainer: classes.customLabelStyle,
                          label: classes.customLabelStyle,
                          active: classes.customLabelStyle,
                          completed: classes.customLabelStyle,
                          alternativeLabel: classes.customLabelStyle,
                        }}
                      >
                        {label}
                      </StepLabel>
                      <StepContent sx={{ marginTop: 5 }}>
                        {checklist && checklist.length > 0 ? (
                          checklist?.map((item: any) => {
                            return renderDynamicComponent(
                              item,
                              componentMapping,
                              {
                                ...workflowHandler,
                                ...(item?.code && {
                                  [item.code]: {
                                    ...workflowHandler[item.code],
                                    others: {
                                      patient,
                                      checklistItem: item,
                                      refreshWorkFlow,
                                    },
                                  },
                                }),
                              }
                            );
                          })
                        ) : (
                          <CircularProgress />
                        )}

                        <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Transitions
                            transitions={transitionList?.filter(
                              (item: any) => item.fromStagesId === activeStep
                            )}
                            handleTransition={handleTransition}
                          />
                        </Box>

                        <ErrorContainer
                          showErrorContainer={showErrorContainer}
                          errorContainerTitle={errorContainerTitle}
                          errorContainerList={errorContainerList}
                        />
                      </StepContent>
                    </Step>
                  );
                })}
              </Stepper>

              {activeStep === steps.length + 1 && (
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                </>
              )}
            </Box>

            <ConfirmDialog
              isOpen={workflowData?.confirmation}
              handlerConfirmation={handlerConfirmation}
              handlerIsOpenConfirmDialog={
                workflowHandler["setConfirmation"]["handler"]
              }
            />

            <DispositionDialog
              isOpen={workflowData?.disposition}
              withoutReason={workflowData?.noReasonForDisposition}
              handlerDisposition={handlerDisposition}
              handlerIsOpenDispositionDialog={
                workflowHandler["setDisposition"]["handler"]
              }
              saveDispositions={setDispositions}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
